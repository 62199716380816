import { useTheme } from '@mui/styles';
import DryjanGoalBannerImage from '../../../../public/img/sunnyside/home_dryish_january_banner.png';
import DryjanGoalBannerImageMobile from '../../../../public/img/sunnyside/home_dryish_january_banner_mobile.png';
import Image from '../../../components_sunnyside/image';
import { Box, styled } from '@mui/material';
import { useRouter } from 'next/router';

export default function HomeDryishJanuaryBanner(): JSX.Element {
    const router = useRouter();
    const theme = useTheme();
    const Banner = styled(Box)({
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    });
    const MobileBanner = styled(Box)({
        cursor: 'pointer',
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    });
    const handleOnClick = () => {
        router.push('dryish-january');
    };
    return (
        <>
            <Banner onClick={handleOnClick}>
                <Image src={DryjanGoalBannerImage} />
            </Banner>
            <MobileBanner onClick={handleOnClick}>
                <Image src={DryjanGoalBannerImageMobile} />
            </MobileBanner>
        </>
    );
}