import React from 'react';
import { PrismicHomePageData } from '../utils/prismic/types/pages/home.type';
import { PrismicPaginatedResponse } from '../utils/prismic/types';
import SunnysideWrapper from '../components_sunnyside/wrapper';
import Head from 'next/head';
import { Box, BoxProps, Typography, styled } from '@mui/material';
import HomeAnnouncement from '../sections/home/home_announcement';
import SunnysideMain from '../components_sunnyside/main';
import HomeDryishJanuaryBanner from '../sections/home/home_dryish_january';
import { isDryJan } from '../logic/dryJan';

import SunnysideHeader from '../components_sunnyside/header/2';
import HomeBenefits from '../sections/home/home_benefits';
import HomeTestimonials from '../sections/home/home_testimonials_3';
import SunnysideFooter from '../components_sunnyside/footer_2';
import HomeBanner from '../sections/home/home_banner_6';
import HomeScience from '../sections/home/home_science_3';
import HomeExperts from '../sections/home/home_experts';
import HomeFeatures from '../sections/home/home_features_2';
import HomePromise from '../sections/home/home_promise';
import HomeTeam from '../sections/home/home_team';
import FeaturedIn from '../sections/reusable/featured_in_2';

type Props = {
    data?: PrismicPaginatedResponse<PrismicHomePageData>,
    couponResponse?: {
        percent_off: string
    }
}

export default function Home({ data, couponResponse }: Props): JSX.Element {
    const DiscountBanner = styled(Box)<BoxProps>(() => ({
        backgroundColor: '#77BEF8',
        fontSize: '1em',
        textAlign: 'center',
        width: '100%'
    }));
    return (
        <SunnysideWrapper>
            <Head>
                <meta 
                    name="description" 
                    content="Sleep better, save money, and have more energy with proven techniques that build healthier drinking habits. It’s 100% personalized to you." />
                <link rel="canonical" href="https://www.sunnyside.co" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@joinsunnyside" />
                <meta name="twitter:creator" content="@joinsunnyside" />
            </Head>
            {couponResponse?.percent_off && (<DiscountBanner>
                <Typography style={{color: 'white'}}>
                    Congrats! A {couponResponse.percent_off}% discount has been applied for you.
                </Typography>
            </DiscountBanner>)}
            {data?.results[0].data.homepage_announcement_bar && <HomeAnnouncement data={data.results[0]}/>}
            <SunnysideHeader />
            <SunnysideMain>
                <Typography variant="h1" margin="-1000rem" position="absolute">
                    Sunnyside - Mindful Drinking &amp; Alcohol Tracking App
                </Typography>
                <HomeBanner />
                {isDryJan() && <HomeDryishJanuaryBanner />}
                <FeaturedIn />
                <HomeScience />
                <HomeExperts />
                <HomeBenefits />
                <HomeFeatures />
                <HomeTestimonials />
                <HomePromise />
                <HomeTeam />
            </SunnysideMain>
            <SunnysideFooter />
        </SunnysideWrapper>
    );
}
