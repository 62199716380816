import NextImage, { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    loadingImage: {
        opacity: 0,
        transition: 'ease-in-out all 500ms'
    },
    loaded: {
        opacity: 1
    }
}));

export default function Image(props: ImageProps) {
    const [isLoaded, setIsLoaded] = useState(false);
    const classes = useStyles();

    const onLoadCallback = () => {
        setIsLoaded(true);
    };

    useEffect(() => {
        return () => {
            setIsLoaded(false);
        };
    }, []);

    return (
        <NextImage
            onLoadingComplete={onLoadCallback}
            {...props}
            className={clsx([classes.loadingImage, { [classes.loaded]: isLoaded }])}
        />
    );
}